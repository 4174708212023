import { ThemeType } from "src/common/enums";
import HeaderLogo from "../images/logo.webp";
import FooterLogo from "../images/logo.footer.webp";

const VectorLogo = undefined;

const defaultTheme = ThemeType.LIGHT;
const hasThemeSwitcher = false;
const hasNetworkSwitcher = true;
const hasWhatsappWidget = false;
const hasBanner = true;
const isDisableWalletLogin = false;

const headerLogoSize = 64;
const DarkHeaderLogo = HeaderLogo;
const DarkFooterLogo = FooterLogo;
const footerLogoSize = 64;

export {
  VectorLogo,
  HeaderLogo,
  DarkHeaderLogo,
  headerLogoSize,
  FooterLogo,
  DarkFooterLogo,
  footerLogoSize,
  defaultTheme,
  hasThemeSwitcher,
  hasNetworkSwitcher,
  hasWhatsappWidget,
  hasBanner,
  isDisableWalletLogin,
};
